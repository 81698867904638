// source: proto/notification_setting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.ListNewFaqNotificationUsersRequest', null, global);
goog.exportSymbol('proto.ListNewFaqNotificationUsersResponse', null, global);
goog.exportSymbol('proto.ListNewTicketNotificationUsersRequest', null, global);
goog.exportSymbol('proto.ListNewTicketNotificationUsersResponse', null, global);
goog.exportSymbol('proto.NewFaqNotificationUser', null, global);
goog.exportSymbol('proto.NewTicketNotificationUser', null, global);
goog.exportSymbol('proto.UpdateNewFaqNotificationUsersRequest', null, global);
goog.exportSymbol('proto.UpdateNewFaqNotificationUsersResponse', null, global);
goog.exportSymbol('proto.UpdateNewTicketNotificationUsersRequest', null, global);
goog.exportSymbol('proto.UpdateNewTicketNotificationUsersResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NewTicketNotificationUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NewTicketNotificationUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NewTicketNotificationUser.displayName = 'proto.NewTicketNotificationUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NewFaqNotificationUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NewFaqNotificationUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NewFaqNotificationUser.displayName = 'proto.NewFaqNotificationUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateNewTicketNotificationUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateNewTicketNotificationUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateNewTicketNotificationUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateNewTicketNotificationUsersRequest.displayName = 'proto.UpdateNewTicketNotificationUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateNewTicketNotificationUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateNewTicketNotificationUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.UpdateNewTicketNotificationUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateNewTicketNotificationUsersResponse.displayName = 'proto.UpdateNewTicketNotificationUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListNewTicketNotificationUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListNewTicketNotificationUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListNewTicketNotificationUsersRequest.displayName = 'proto.ListNewTicketNotificationUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListNewTicketNotificationUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListNewTicketNotificationUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.ListNewTicketNotificationUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListNewTicketNotificationUsersResponse.displayName = 'proto.ListNewTicketNotificationUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateNewFaqNotificationUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateNewFaqNotificationUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateNewFaqNotificationUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateNewFaqNotificationUsersRequest.displayName = 'proto.UpdateNewFaqNotificationUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateNewFaqNotificationUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateNewFaqNotificationUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.UpdateNewFaqNotificationUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateNewFaqNotificationUsersResponse.displayName = 'proto.UpdateNewFaqNotificationUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListNewFaqNotificationUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListNewFaqNotificationUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListNewFaqNotificationUsersRequest.displayName = 'proto.ListNewFaqNotificationUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListNewFaqNotificationUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListNewFaqNotificationUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.ListNewFaqNotificationUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListNewFaqNotificationUsersResponse.displayName = 'proto.ListNewFaqNotificationUsersResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NewTicketNotificationUser.prototype.toObject = function(opt_includeInstance) {
  return proto.NewTicketNotificationUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NewTicketNotificationUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NewTicketNotificationUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NewTicketNotificationUser}
 */
proto.NewTicketNotificationUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NewTicketNotificationUser;
  return proto.NewTicketNotificationUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NewTicketNotificationUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NewTicketNotificationUser}
 */
proto.NewTicketNotificationUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NewTicketNotificationUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NewTicketNotificationUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NewTicketNotificationUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NewTicketNotificationUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.NewTicketNotificationUser.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NewTicketNotificationUser} returns this
 */
proto.NewTicketNotificationUser.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NewFaqNotificationUser.prototype.toObject = function(opt_includeInstance) {
  return proto.NewFaqNotificationUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NewFaqNotificationUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NewFaqNotificationUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NewFaqNotificationUser}
 */
proto.NewFaqNotificationUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NewFaqNotificationUser;
  return proto.NewFaqNotificationUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NewFaqNotificationUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NewFaqNotificationUser}
 */
proto.NewFaqNotificationUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NewFaqNotificationUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NewFaqNotificationUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NewFaqNotificationUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NewFaqNotificationUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.NewFaqNotificationUser.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NewFaqNotificationUser} returns this
 */
proto.NewFaqNotificationUser.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateNewTicketNotificationUsersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateNewTicketNotificationUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateNewTicketNotificationUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateNewTicketNotificationUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewTicketNotificationUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newTicketNotificationUsersList: jspb.Message.toObjectList(msg.getNewTicketNotificationUsersList(),
    proto.NewTicketNotificationUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateNewTicketNotificationUsersRequest}
 */
proto.UpdateNewTicketNotificationUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateNewTicketNotificationUsersRequest;
  return proto.UpdateNewTicketNotificationUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateNewTicketNotificationUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateNewTicketNotificationUsersRequest}
 */
proto.UpdateNewTicketNotificationUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NewTicketNotificationUser;
      reader.readMessage(value,proto.NewTicketNotificationUser.deserializeBinaryFromReader);
      msg.addNewTicketNotificationUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateNewTicketNotificationUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateNewTicketNotificationUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateNewTicketNotificationUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewTicketNotificationUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewTicketNotificationUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NewTicketNotificationUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NewTicketNotificationUser new_ticket_notification_users = 1;
 * @return {!Array<!proto.NewTicketNotificationUser>}
 */
proto.UpdateNewTicketNotificationUsersRequest.prototype.getNewTicketNotificationUsersList = function() {
  return /** @type{!Array<!proto.NewTicketNotificationUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NewTicketNotificationUser, 1));
};


/**
 * @param {!Array<!proto.NewTicketNotificationUser>} value
 * @return {!proto.UpdateNewTicketNotificationUsersRequest} returns this
*/
proto.UpdateNewTicketNotificationUsersRequest.prototype.setNewTicketNotificationUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NewTicketNotificationUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NewTicketNotificationUser}
 */
proto.UpdateNewTicketNotificationUsersRequest.prototype.addNewTicketNotificationUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NewTicketNotificationUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateNewTicketNotificationUsersRequest} returns this
 */
proto.UpdateNewTicketNotificationUsersRequest.prototype.clearNewTicketNotificationUsersList = function() {
  return this.setNewTicketNotificationUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateNewTicketNotificationUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateNewTicketNotificationUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateNewTicketNotificationUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateNewTicketNotificationUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewTicketNotificationUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newTicketNotificationUsersList: jspb.Message.toObjectList(msg.getNewTicketNotificationUsersList(),
    proto.NewTicketNotificationUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateNewTicketNotificationUsersResponse}
 */
proto.UpdateNewTicketNotificationUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateNewTicketNotificationUsersResponse;
  return proto.UpdateNewTicketNotificationUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateNewTicketNotificationUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateNewTicketNotificationUsersResponse}
 */
proto.UpdateNewTicketNotificationUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NewTicketNotificationUser;
      reader.readMessage(value,proto.NewTicketNotificationUser.deserializeBinaryFromReader);
      msg.addNewTicketNotificationUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateNewTicketNotificationUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateNewTicketNotificationUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateNewTicketNotificationUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewTicketNotificationUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewTicketNotificationUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NewTicketNotificationUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NewTicketNotificationUser new_ticket_notification_users = 1;
 * @return {!Array<!proto.NewTicketNotificationUser>}
 */
proto.UpdateNewTicketNotificationUsersResponse.prototype.getNewTicketNotificationUsersList = function() {
  return /** @type{!Array<!proto.NewTicketNotificationUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NewTicketNotificationUser, 1));
};


/**
 * @param {!Array<!proto.NewTicketNotificationUser>} value
 * @return {!proto.UpdateNewTicketNotificationUsersResponse} returns this
*/
proto.UpdateNewTicketNotificationUsersResponse.prototype.setNewTicketNotificationUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NewTicketNotificationUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NewTicketNotificationUser}
 */
proto.UpdateNewTicketNotificationUsersResponse.prototype.addNewTicketNotificationUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NewTicketNotificationUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateNewTicketNotificationUsersResponse} returns this
 */
proto.UpdateNewTicketNotificationUsersResponse.prototype.clearNewTicketNotificationUsersList = function() {
  return this.setNewTicketNotificationUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListNewTicketNotificationUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListNewTicketNotificationUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListNewTicketNotificationUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewTicketNotificationUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListNewTicketNotificationUsersRequest}
 */
proto.ListNewTicketNotificationUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListNewTicketNotificationUsersRequest;
  return proto.ListNewTicketNotificationUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListNewTicketNotificationUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListNewTicketNotificationUsersRequest}
 */
proto.ListNewTicketNotificationUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListNewTicketNotificationUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListNewTicketNotificationUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListNewTicketNotificationUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewTicketNotificationUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListNewTicketNotificationUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListNewTicketNotificationUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListNewTicketNotificationUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListNewTicketNotificationUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewTicketNotificationUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newTicketNotificationUsersList: jspb.Message.toObjectList(msg.getNewTicketNotificationUsersList(),
    proto.NewTicketNotificationUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListNewTicketNotificationUsersResponse}
 */
proto.ListNewTicketNotificationUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListNewTicketNotificationUsersResponse;
  return proto.ListNewTicketNotificationUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListNewTicketNotificationUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListNewTicketNotificationUsersResponse}
 */
proto.ListNewTicketNotificationUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NewTicketNotificationUser;
      reader.readMessage(value,proto.NewTicketNotificationUser.deserializeBinaryFromReader);
      msg.addNewTicketNotificationUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListNewTicketNotificationUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListNewTicketNotificationUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListNewTicketNotificationUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewTicketNotificationUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewTicketNotificationUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NewTicketNotificationUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NewTicketNotificationUser new_ticket_notification_users = 1;
 * @return {!Array<!proto.NewTicketNotificationUser>}
 */
proto.ListNewTicketNotificationUsersResponse.prototype.getNewTicketNotificationUsersList = function() {
  return /** @type{!Array<!proto.NewTicketNotificationUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NewTicketNotificationUser, 1));
};


/**
 * @param {!Array<!proto.NewTicketNotificationUser>} value
 * @return {!proto.ListNewTicketNotificationUsersResponse} returns this
*/
proto.ListNewTicketNotificationUsersResponse.prototype.setNewTicketNotificationUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NewTicketNotificationUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NewTicketNotificationUser}
 */
proto.ListNewTicketNotificationUsersResponse.prototype.addNewTicketNotificationUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NewTicketNotificationUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListNewTicketNotificationUsersResponse} returns this
 */
proto.ListNewTicketNotificationUsersResponse.prototype.clearNewTicketNotificationUsersList = function() {
  return this.setNewTicketNotificationUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateNewFaqNotificationUsersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateNewFaqNotificationUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateNewFaqNotificationUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateNewFaqNotificationUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewFaqNotificationUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newFaqNotificationUsersList: jspb.Message.toObjectList(msg.getNewFaqNotificationUsersList(),
    proto.NewFaqNotificationUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateNewFaqNotificationUsersRequest}
 */
proto.UpdateNewFaqNotificationUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateNewFaqNotificationUsersRequest;
  return proto.UpdateNewFaqNotificationUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateNewFaqNotificationUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateNewFaqNotificationUsersRequest}
 */
proto.UpdateNewFaqNotificationUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NewFaqNotificationUser;
      reader.readMessage(value,proto.NewFaqNotificationUser.deserializeBinaryFromReader);
      msg.addNewFaqNotificationUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateNewFaqNotificationUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateNewFaqNotificationUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateNewFaqNotificationUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewFaqNotificationUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewFaqNotificationUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NewFaqNotificationUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NewFaqNotificationUser new_faq_notification_users = 1;
 * @return {!Array<!proto.NewFaqNotificationUser>}
 */
proto.UpdateNewFaqNotificationUsersRequest.prototype.getNewFaqNotificationUsersList = function() {
  return /** @type{!Array<!proto.NewFaqNotificationUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NewFaqNotificationUser, 1));
};


/**
 * @param {!Array<!proto.NewFaqNotificationUser>} value
 * @return {!proto.UpdateNewFaqNotificationUsersRequest} returns this
*/
proto.UpdateNewFaqNotificationUsersRequest.prototype.setNewFaqNotificationUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NewFaqNotificationUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NewFaqNotificationUser}
 */
proto.UpdateNewFaqNotificationUsersRequest.prototype.addNewFaqNotificationUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NewFaqNotificationUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateNewFaqNotificationUsersRequest} returns this
 */
proto.UpdateNewFaqNotificationUsersRequest.prototype.clearNewFaqNotificationUsersList = function() {
  return this.setNewFaqNotificationUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateNewFaqNotificationUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateNewFaqNotificationUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateNewFaqNotificationUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateNewFaqNotificationUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewFaqNotificationUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newFaqNotificationUsersList: jspb.Message.toObjectList(msg.getNewFaqNotificationUsersList(),
    proto.NewFaqNotificationUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateNewFaqNotificationUsersResponse}
 */
proto.UpdateNewFaqNotificationUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateNewFaqNotificationUsersResponse;
  return proto.UpdateNewFaqNotificationUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateNewFaqNotificationUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateNewFaqNotificationUsersResponse}
 */
proto.UpdateNewFaqNotificationUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NewFaqNotificationUser;
      reader.readMessage(value,proto.NewFaqNotificationUser.deserializeBinaryFromReader);
      msg.addNewFaqNotificationUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateNewFaqNotificationUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateNewFaqNotificationUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateNewFaqNotificationUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewFaqNotificationUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewFaqNotificationUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NewFaqNotificationUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NewFaqNotificationUser new_faq_notification_users = 1;
 * @return {!Array<!proto.NewFaqNotificationUser>}
 */
proto.UpdateNewFaqNotificationUsersResponse.prototype.getNewFaqNotificationUsersList = function() {
  return /** @type{!Array<!proto.NewFaqNotificationUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NewFaqNotificationUser, 1));
};


/**
 * @param {!Array<!proto.NewFaqNotificationUser>} value
 * @return {!proto.UpdateNewFaqNotificationUsersResponse} returns this
*/
proto.UpdateNewFaqNotificationUsersResponse.prototype.setNewFaqNotificationUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NewFaqNotificationUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NewFaqNotificationUser}
 */
proto.UpdateNewFaqNotificationUsersResponse.prototype.addNewFaqNotificationUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NewFaqNotificationUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateNewFaqNotificationUsersResponse} returns this
 */
proto.UpdateNewFaqNotificationUsersResponse.prototype.clearNewFaqNotificationUsersList = function() {
  return this.setNewFaqNotificationUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListNewFaqNotificationUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListNewFaqNotificationUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListNewFaqNotificationUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewFaqNotificationUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListNewFaqNotificationUsersRequest}
 */
proto.ListNewFaqNotificationUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListNewFaqNotificationUsersRequest;
  return proto.ListNewFaqNotificationUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListNewFaqNotificationUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListNewFaqNotificationUsersRequest}
 */
proto.ListNewFaqNotificationUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListNewFaqNotificationUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListNewFaqNotificationUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListNewFaqNotificationUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewFaqNotificationUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListNewFaqNotificationUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListNewFaqNotificationUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListNewFaqNotificationUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListNewFaqNotificationUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewFaqNotificationUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newFaqNotificationUsersList: jspb.Message.toObjectList(msg.getNewFaqNotificationUsersList(),
    proto.NewFaqNotificationUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListNewFaqNotificationUsersResponse}
 */
proto.ListNewFaqNotificationUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListNewFaqNotificationUsersResponse;
  return proto.ListNewFaqNotificationUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListNewFaqNotificationUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListNewFaqNotificationUsersResponse}
 */
proto.ListNewFaqNotificationUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NewFaqNotificationUser;
      reader.readMessage(value,proto.NewFaqNotificationUser.deserializeBinaryFromReader);
      msg.addNewFaqNotificationUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListNewFaqNotificationUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListNewFaqNotificationUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListNewFaqNotificationUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListNewFaqNotificationUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewFaqNotificationUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NewFaqNotificationUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NewFaqNotificationUser new_faq_notification_users = 1;
 * @return {!Array<!proto.NewFaqNotificationUser>}
 */
proto.ListNewFaqNotificationUsersResponse.prototype.getNewFaqNotificationUsersList = function() {
  return /** @type{!Array<!proto.NewFaqNotificationUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NewFaqNotificationUser, 1));
};


/**
 * @param {!Array<!proto.NewFaqNotificationUser>} value
 * @return {!proto.ListNewFaqNotificationUsersResponse} returns this
*/
proto.ListNewFaqNotificationUsersResponse.prototype.setNewFaqNotificationUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NewFaqNotificationUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NewFaqNotificationUser}
 */
proto.ListNewFaqNotificationUsersResponse.prototype.addNewFaqNotificationUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NewFaqNotificationUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListNewFaqNotificationUsersResponse} returns this
 */
proto.ListNewFaqNotificationUsersResponse.prototype.clearNewFaqNotificationUsersList = function() {
  return this.setNewFaqNotificationUsersList([]);
};


goog.object.extend(exports, proto);
